
let model = {};
model.dataForm = {
    "id": undefined, // optional - Mã tự sinh
    "name": undefined, // required - Tên của popup
}
model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "name",
    defaultSort: ["name", "ascending"],
    fields: "id,name",
    filters: "",
    dataSearch: {
        value: {
            name: "",
        },
        operator: {
            name: ":regex_i:",
        },
    },
};

export default model;
